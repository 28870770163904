/*!
 *
 *  Project:  GLYPHICONS HALFLINGS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
.halflings-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("img/glyphicons-halflings.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  *display: inline;
  *zoom: 1;
  *margin-right: .3em;
}
.no-inlinesvg .halflings-icon {
  background-image: url("img/glyphicons-halflings.png");
}
.halflings-icon.white {
  background-image: url("img/glyphicons-halflings-white.png");
}
.halflings-icon.glass {
  background-position: 0 0;
}
.halflings-icon.music {
  background-position: -24px 0;
}
.halflings-icon.search {
  background-position: -48px 0;
}
.halflings-icon.envelope {
  background-position: -72px 0;
}
.halflings-icon.heart {
  background-position: -96px 0;
}
.halflings-icon.star {
  background-position: -120px 0;
}
.halflings-icon.star-empty {
  background-position: -144px 0;
}
.halflings-icon.user {
  background-position: -168px 0;
}
.halflings-icon.film {
  background-position: -192px 0;
}
.halflings-icon.th-large {
  background-position: -216px 0;
}
.halflings-icon.th {
  background-position: -240px 0;
}
.halflings-icon.th-list {
  background-position: -264px 0;
}
.halflings-icon.ok {
  background-position: -288px 0;
}
.halflings-icon.remove {
  background-position: -312px 0;
}
.halflings-icon.zoom-in {
  background-position: -336px 0;
}
.halflings-icon.zoom-out {
  background-position: -360px 0;
}
.halflings-icon.off {
  background-position: -384px 0;
}
.halflings-icon.signal {
  background-position: -408px 0;
}
.halflings-icon.cog {
  background-position: -432px 0;
}
.halflings-icon.trash {
  background-position: -456px 0;
}
.halflings-icon.home {
  background-position: 0 -24px;
}
.halflings-icon.file {
  background-position: -24px -24px;
}
.halflings-icon.time {
  background-position: -48px -24px;
}
.halflings-icon.road {
  background-position: -72px -24px;
}
.halflings-icon.download-alt {
  background-position: -96px -24px;
}
.halflings-icon.download {
  background-position: -120px -24px;
}
.halflings-icon.upload {
  background-position: -144px -24px;
}
.halflings-icon.inbox {
  background-position: -168px -24px;
}
.halflings-icon.play-circle {
  background-position: -192px -24px;
}
.halflings-icon.repeat {
  background-position: -216px -24px;
}
.halflings-icon.refresh {
  background-position: -240px -24px;
}
.halflings-icon.list-alt {
  background-position: -264px -24px;
}
.halflings-icon.lock {
  background-position: -287px -24px;
}
.halflings-icon.flag {
  background-position: -312px -24px;
}
.halflings-icon.headphones {
  background-position: -336px -24px;
}
.halflings-icon.volume-off {
  background-position: -360px -24px;
}
.halflings-icon.volume-down {
  background-position: -384px -24px;
}
.halflings-icon.volume-up {
  background-position: -408px -24px;
}
.halflings-icon.qrcode {
  background-position: -432px -24px;
}
.halflings-icon.barcode {
  background-position: -456px -24px;
}
.halflings-icon.tag {
  background-position: 0 -48px;
}
.halflings-icon.tags {
  background-position: -25px -48px;
}
.halflings-icon.book {
  background-position: -48px -48px;
}
.halflings-icon.bookmark {
  background-position: -72px -48px;
}
.halflings-icon.print {
  background-position: -96px -48px;
}
.halflings-icon.camera {
  background-position: -120px -48px;
}
.halflings-icon.font {
  background-position: -144px -48px;
}
.halflings-icon.bold {
  background-position: -167px -48px;
}
.halflings-icon.italic {
  background-position: -192px -48px;
}
.halflings-icon.text-height {
  background-position: -216px -48px;
}
.halflings-icon.text-width {
  background-position: -240px -48px;
}
.halflings-icon.align-left {
  background-position: -264px -48px;
}
.halflings-icon.align-center {
  background-position: -288px -48px;
}
.halflings-icon.align-right {
  background-position: -312px -48px;
}
.halflings-icon.align-justify {
  background-position: -336px -48px;
}
.halflings-icon.list {
  background-position: -360px -48px;
}
.halflings-icon.indent-left {
  background-position: -384px -48px;
}
.halflings-icon.indent-right {
  background-position: -408px -48px;
}
.halflings-icon.facetime-video {
  background-position: -432px -48px;
}
.halflings-icon.picture {
  background-position: -456px -48px;
}
.halflings-icon.pencil {
  background-position: 0 -72px;
}
.halflings-icon.map-marker {
  background-position: -24px -72px;
}
.halflings-icon.adjust {
  background-position: -48px -72px;
}
.halflings-icon.tint {
  background-position: -72px -72px;
}
.halflings-icon.edit {
  background-position: -96px -72px;
}
.halflings-icon.share {
  background-position: -120px -72px;
}
.halflings-icon.check {
  background-position: -144px -72px;
}
.halflings-icon.move {
  background-position: -168px -72px;
}
.halflings-icon.step-backward {
  background-position: -192px -72px;
}
.halflings-icon.fast-backward {
  background-position: -216px -72px;
}
.halflings-icon.backward {
  background-position: -240px -72px;
}
.halflings-icon.play {
  background-position: -264px -72px;
}
.halflings-icon.pause {
  background-position: -288px -72px;
}
.halflings-icon.stop {
  background-position: -312px -72px;
}
.halflings-icon.forward {
  background-position: -336px -72px;
}
.halflings-icon.fast-forward {
  background-position: -360px -72px;
}
.halflings-icon.step-forward {
  background-position: -384px -72px;
}
.halflings-icon.eject {
  background-position: -408px -72px;
}
.halflings-icon.chevron-left {
  background-position: -432px -72px;
}
.halflings-icon.chevron-right {
  background-position: -456px -72px;
}
.halflings-icon.plus-sign {
  background-position: 0 -96px;
}
.halflings-icon.minus-sign {
  background-position: -24px -96px;
}
.halflings-icon.remove-sign {
  background-position: -48px -96px;
}
.halflings-icon.ok-sign {
  background-position: -72px -96px;
}
.halflings-icon.question-sign {
  background-position: -96px -96px;
}
.halflings-icon.info-sign {
  background-position: -120px -96px;
}
.halflings-icon.screenshot {
  background-position: -144px -96px;
}
.halflings-icon.remove-circle {
  background-position: -168px -96px;
}
.halflings-icon.ok-circle {
  background-position: -192px -96px;
}
.halflings-icon.ban-circle {
  background-position: -216px -96px;
}
.halflings-icon.arrow-left {
  background-position: -240px -96px;
}
.halflings-icon.arrow-right {
  background-position: -264px -96px;
}
.halflings-icon.arrow-up {
  background-position: -288px -96px;
}
.halflings-icon.arrow-down {
  background-position: -312px -96px;
}
.halflings-icon.share-alt {
  background-position: -336px -96px;
}
.halflings-icon.resize-full {
  background-position: -360px -96px;
}
.halflings-icon.resize-small {
  background-position: -384px -96px;
}
.halflings-icon.plus {
  background-position: -408px -96px;
}
.halflings-icon.minus {
  background-position: -433px -96px;
}
.halflings-icon.asterisk {
  background-position: -456px -96px;
}
.halflings-icon.exclamation-sign {
  background-position: 0 -120px;
}
.halflings-icon.gift {
  background-position: -24px -120px;
}
.halflings-icon.leaf {
  background-position: -48px -120px;
}
.halflings-icon.fire {
  background-position: -72px -120px;
}
.halflings-icon.eye-open {
  background-position: -96px -120px;
}
.halflings-icon.eye-close {
  background-position: -120px -120px;
}
.halflings-icon.warning-sign {
  background-position: -144px -120px;
}
.halflings-icon.plane {
  background-position: -168px -120px;
}
.halflings-icon.calendar {
  background-position: -192px -120px;
}
.halflings-icon.random {
  background-position: -216px -120px;
}
.halflings-icon.comments {
  background-position: -240px -120px;
}
.halflings-icon.magnet {
  background-position: -264px -120px;
}
.halflings-icon.chevron-up {
  background-position: -288px -120px;
}
.halflings-icon.chevron-down {
  background-position: -313px -119px;
}
.halflings-icon.retweet {
  background-position: -336px -120px;
}
.halflings-icon.shopping-cart {
  background-position: -360px -120px;
}
.halflings-icon.folder-close {
  background-position: -384px -120px;
}
.halflings-icon.folder-open {
  background-position: -408px -120px;
}
.halflings-icon.resize-vertical {
  background-position: -432px -120px;
}
.halflings-icon.resize-horizontal {
  background-position: -456px -120px;
}
.halflings-icon.hdd {
  background-position: 0px -144px;
}
.halflings-icon.bullhorn {
  background-position: -24px -144px;
}
.halflings-icon.bell {
  background-position: -48px -144px;
}
.halflings-icon.certificate {
  background-position: -72px -144px;
}
.halflings-icon.thumbs-up {
  background-position: -96px -144px;
}
.halflings-icon.thumbs-down {
  background-position: -120px -144px;
}
.halflings-icon.hand-right {
  background-position: -144px -144px;
}
.halflings-icon.hand-left {
  background-position: -168px -144px;
}
.halflings-icon.hand-top {
  background-position: -192px -144px;
}
.halflings-icon.hand-down {
  background-position: -216px -144px;
}
.halflings-icon.circle-arrow-right {
  background-position: -240px -144px;
}
.halflings-icon.circle-arrow-left {
  background-position: -264px -144px;
}
.halflings-icon.circle-arrow-top {
  background-position: -288px -144px;
}
.halflings-icon.circle-arrow-down {
  background-position: -313px -144px;
}
.halflings-icon.globe {
  background-position: -336px -144px;
}
.halflings-icon.wrench {
  background-position: -360px -144px;
}
.halflings-icon.tasks {
  background-position: -384px -144px;
}
.halflings-icon.filter {
  background-position: -408px -144px;
}
.halflings-icon.briefcase {
  background-position: -432px -144px;
}
.halflings-icon.fullscreen {
  background-position: -456px -144px;
}
.halflings-icon.dashboard {
  background-position: 0px -168px;
}
.halflings-icon.paperclip {
  background-position: -24px -168px;
}
.halflings-icon.heart-empty {
  background-position: -48px -168px;
}
.halflings-icon.link {
  background-position: -72px -168px;
}
.halflings-icon.phone {
  background-position: -96px -168px;
}
.halflings-icon.pushpin {
  background-position: -120px -168px;
}
.halflings-icon.euro {
  background-position: -144px -168px;
}
.halflings-icon.usd {
  background-position: -168px -168px;
}
.halflings-icon.gbp {
  background-position: -192px -168px;
}
.halflings-icon.sort {
  background-position: -216px -168px;
}
.halflings-icon.sort-by-alphabet {
  background-position: -240px -168px;
}
.halflings-icon.sort-by-alphabet-alt {
  background-position: -264px -168px;
}
.halflings-icon.sort-by-order {
  background-position: -288px -168px;
}
.halflings-icon.sort-by-order-alt {
  background-position: -313px -168px;
}
.halflings-icon.sort-by-attributes {
  background-position: -336px -168px;
}
.halflings-icon.sort-by-attributes-alt {
  background-position: -360px -168px;
}
.halflings-icon.unchecked {
  background-position: -384px -168px;
}
.halflings-icon.expand {
  background-position: -408px -168px;
}
.halflings-icon.collapse {
  background-position: -432px -168px;
}
.halflings-icon.collapse-top {
  background-position: -456px -168px;
}
