ul.sv-autocomplete-search-result,
ul.sv-autocomplete-search-result .sv-autocomplete-result-item {
   -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
           box-sizing: border-box;
}

ul.sv-autocomplete-search-result {
   background-color: #fff;
   float: left;
   list-style: none;
   padding: 0;
   padding: 3px 0;
   border: 1px solid #ccc;
   border: 1px solid rgba(0,0,0,.15);
   border-radius: 4px;
   -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
           box-shadow: 0 6px 12px rgba(0,0,0,.175);

   font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
   font-size: 14px;
   line-height: 1.42857143;
   color: #333;

   max-width: 500px;
   z-index: 9999;
}

ul.sv-autocomplete-search-result .sv-autocomplete-result-item {
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   padding: 3px 20px;
}

ul.sv-autocomplete-search-result .sv-autocomplete-result-item:hover,
ul.sv-autocomplete-search-result .sv-autocomplete-result-item.sv-autocomplete-result-item-active {
   background-color: #f5f5f5;
   color: #262626;
}

ul.sv-autocomplete-search-result .sv-autocomplete-result-item a {
   text-decoration: none;
}

